import { Auth } from "./Auth";
import { ImCheckmark } from "react-icons/im";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="metakeep-logo">
        <img src="/images/metakeep-logo.svg" alt="" />
      </div>
      <div className="split-container">
        <Auth />
      </div>
      <div className="split-container black-bg">
        <div className="home-copy-container">
          <div>
            <div className="home-copy-title">
              <div>Peace of mind.</div>
              <div>That's MetaKeep.</div>
            </div>
            <div className="home-copy-list">
              <div className="copy-list-item">
                <ImCheckmark />
                Strongest Security 🥇
              </div>
              <div className="copy-list-item">
                <ImCheckmark />
                Lowest User Friction ✨
              </div>
              <div className="copy-list-item">
                <ImCheckmark />
                Planet Scale 🌎
              </div>
              <div className="copy-list-item">
                <ImCheckmark />
                Lowest Time To Market 🏁
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
