import { useState, useMemo, useCallback, useEffect } from "react";
import { Auth as AWSAuth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { SigninForm } from "./Signin";
import { SignupForm } from "./Signup";
import { QRCodeModal } from "./QRCodeModal";
import { CodeModal } from "./CodeModal";
import { Loader } from "../../../components";

/**
 * @author nadeem@passbird.co
 * @description Auth Container, most auth logic is handled here.
 * @returns {JSX.Element}
 */
export const Auth = () => {
  const navigate = useNavigate();
  const [currentForm, setCurrentForm] = useState("SIGN_IN");
  const [userCred, setUserCred] = useState({});
  const [codeSrc, setCodeSrc] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [showQRModal, toggleQRModal] = useState(false);
  const [codeModalState, setCodeModalState] = useState({
    isOpen: false,
    isTOTP: true,
  });
  const [authUser, setAuthUser] = useState(null);

  //check if user session exists and redirect to dashboard if so
  const getCurrentSession = useCallback(async () => {
    try {
      const currentSession = await AWSAuth.currentSession();
      setLoading(false);
      if (currentSession) navigate("/dashboard");
    } catch (err) {
      console.log("ERR", err);
      setLoading(false);
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getCurrentSession();
  }, [getCurrentSession]);

  /**
   * @param {object} user
   * @returns String
   * @description This function is used to generate a QR code for the user to scan.
   */
  const generateCodeSrc = useCallback(
    async (user) => {
      const code = await AWSAuth.setupTOTP(user);
      const { email } = userCred;
      const codeSrc =
        "otpauth://totp/MetaKeep:" +
        email +
        "?secret=" +
        code +
        "&issuer=MetaKeep";
      return codeSrc;
    },
    [userCred]
  );

  /**
   * @param {awsUserObject} user
   * This function is used to generate a QR
   * code for the user to scan.
   */
  const mfaSetup = useCallback(
    async (user) => {
      const codeSrc = await generateCodeSrc(user);
      setCodeSrc(codeSrc);
      setAuthUser(user);
      toggleQRModal(true);
    },
    [generateCodeSrc]
  );

  /**
   * @param {string} email
   * @param {string} password
   * handles signup user
   * aws signup requires username field
   * even if we sign up with email
   */
  const onSignupHandler = useCallback(async (email, password) => {
    setUserCred({ email, password });
    const user = await AWSAuth.signUp({ username: email, password });
    setAuthUser(user);
    setCodeModalState({ isOpen: true, isTOTP: false });
  }, []);

  const verifyUserEmail = useCallback(
    async (code) => {
      const { email, password } = userCred;
      await AWSAuth.confirmSignUp(email, code);
      setCodeModalState({ isOpen: false, isTOTP: false });
      const user = await AWSAuth.signIn(email, password);
      await mfaSetup(user);
    },
    [mfaSetup, userCred]
  );

  /**
   * @param {string} email
   * @param {string} password
   * This function is used to sign in the user.
   * if user has not setup MFA ie challengeName = "MFA_SETUP"
   * call mfaSetup function and toggle QR modal else toggle OTP modal
   */
  const onSigninHandler = useCallback(
    async (email, password) => {
      setUserCred({ email, password });
      const user = await signInUser(email, password);
      const { challengeName } = user;
      if (challengeName === "MFA_SETUP") await mfaSetup(user);
      else {
        setAuthUser(user);
        setCodeModalState({ isOpen: true, isTOTP: true });
      }
    },
    [mfaSetup]
  );

  /**
   * @param {string} code
   * confirms the user's MFA code
   * navigate to dashboard if successful
   */
  const confirmSignin = useCallback(
    async (code) => {
      await AWSAuth.confirmSignIn(authUser, code, "SOFTWARE_TOKEN_MFA");
      navigate("/dashboard");
    },
    [navigate, authUser]
  );

  /**
   * @param {string} email
   * @param {string} password
   * handle user sign in
   */
  const signInUser = async (email, password) => {
    const user = await AWSAuth.signIn(email, password);
    return user;
  };

  /**
   * @param {string} otp
   * This function handles MFA setup
   * verify otp first then set users preferred MFA to TOTP
   * navigates to dashboard on success
   */
  const onSubmit = useCallback(
    async (otp) => {
      await AWSAuth.verifyTotpToken(authUser, otp);
      await AWSAuth.setPreferredMFA(authUser, "TOTP");
      navigate("/dashboard");
    },
    [authUser, navigate]
  );

  /**
   * Resend confirmation code
   */
  const resendCode = useCallback(async () => {
    await AWSAuth.resendSignUp(userCred.email);
  }, [userCred.email]);

  const children = useMemo(() => {
    return currentForm === "SIGN_IN" ? (
      <SigninForm
        switchForm={setCurrentForm}
        onSigninHandler={onSigninHandler}
        toggleVerifyUserModal={() => {
          setCodeModalState({ isOpen: true, isTOTP: false });
        }}
      />
    ) : (
      <SignupForm
        switchForm={setCurrentForm}
        onSignupHandler={onSignupHandler}
      />
    );
  }, [currentForm, onSigninHandler, onSignupHandler]);
  const { isTOTP, isOpen } = codeModalState;
  if (isLoading) return <Loader />;
  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        {children}
        <div className="terms-and-conditions">
          By continuing, you agree to the{" "}
          <a href="/">Terms of Service, Privacy Statement</a>, and{" "}
          <a href="/">API & SDK License Agreement.</a>
        </div>
      </div>
      {codeSrc && showQRModal && (
        <QRCodeModal
          isOpen={showQRModal}
          codeSrc={codeSrc}
          onSubmit={onSubmit}
        />
      )}
      {isOpen && (
        <CodeModal
          isTOTP={isTOTP}
          isOpen={isOpen}
          email={userCred?.email || ""}
          onSubmit={isTOTP ? confirmSignin : verifyUserEmail}
          resendCode={resendCode}
        />
      )}
    </div>
  );
};
