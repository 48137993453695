import { useState } from "react";
import { CustomInput } from "../../../../components";
import { Button } from "reactstrap";
import { useForm } from "../../../../hooks";

export const SigninForm = ({
  switchForm,
  onSigninHandler,
  toggleVerifyUserModal,
}) => {
  const [authErr, setAuthErr] = useState(null);

  const { onChangeHandler, values, errors, checkFormValidity } = useForm({
    email: null,
    password: null,
  });

  const { email: emailErr, password: passErr } = errors;
  const { email, password } = values;

  const onLoginClick = async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    try {
      await onSigninHandler(email, password);
    } catch (err) {
      setAuthErr(err.message);
      if (err.message === "User is not confirmed.") toggleVerifyUserModal();
    }
  };

  return (
    <>
      <h1 className="mb-3">Sign In</h1>
      <CustomInput
        placeholder="Email address"
        label="Work Email"
        id="email"
        error={emailErr}
        onChangeHandler={onChangeHandler}
      />
      <CustomInput
        placeholder="Password"
        label="Password"
        id="password"
        type="password"
        error={passErr}
        onChangeHandler={onChangeHandler}
      />
      <div className="auth-btn">
        {authErr && <span className="auth-err">{authErr}</span>}
        <Button onClick={onLoginClick} className="primary-btn lg round">
          Next
        </Button>
      </div>
      <div className="auth-lnk">
        Dont have an account?{" "}
        <span onClick={() => switchForm("SIGN_UP")}>Sign Up Here</span>
      </div>
    </>
  );
};
