import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Loader } from "../../components";
// TODO add test cases
export const withAuth = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const [inProgress, setProgress] = useState(true);

    const getCurrentSession = useCallback(async () => {
      try {
        const currentSession = await Auth.currentSession();
        setProgress(false);
        if (!currentSession) navigate("/");
      } catch (err) {
        console.log("ERR", err);
        setProgress(false);
        navigate("/");
      }
    }, [navigate]);

    useEffect(() => {
      getCurrentSession();
    }, [getCurrentSession]);

    if (inProgress) return <Loader />;
    return <Component {...props} />;
  };
};
