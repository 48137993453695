import { Routes, Route } from "react-router-dom";
import { Home, Dashboard, NotFound } from "./containers";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
