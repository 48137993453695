import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="not-found">
      <b>404</b> Not Found
      <br />
      <Link to="/">Go Home</Link>
    </div>
  );
};
