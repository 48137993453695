import { Modal } from "reactstrap";
import { LoaderWithBackdrop, OTPField } from "../../../../components";
import { useForm } from "../../../../hooks";
import { useCallback, useEffect, useState } from "react";

export const CodeModal = ({
  isOpen = true,
  onSubmit,
  isTOTP,
  resendCode,
  email,
}) => {
  const { setDirectValue, values, checkFormValidity } = useForm({
    OTP: null,
  });
  const [inProgress, setProgress] = useState(false);
  const [authMsg, setAuthMsg] = useState({ msg: null, isError: false });

  const { OTP } = values;

  const onVerify = useCallback(async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    try {
      setProgress(true);
      await onSubmit(OTP);
    } catch (err) {
      setAuthMsg({ msg: err.message, isError: true });
      setProgress(false);
      console.log("err", err.message);
    }
  }, [OTP, checkFormValidity, onSubmit]);

  useEffect(() => {
    if (OTP && OTP.length === 6) onVerify();
  }, [OTP, onVerify]);

  const onResendCodeClick = async () => {
    try {
      await resendCode();
      setAuthMsg({
        msg: "A new code has been sent to your email",
        isError: false,
      });
    } catch (err) {
      setAuthMsg({ msg: err.message, isError: true });
    }
  };

  const handleCodeChange = useCallback(
    (val) => {
      setDirectValue("OTP", val);
    },
    [setDirectValue]
  );
  return (
    <Modal centered className="auth-modal" isOpen={isOpen}>
      <div className="auth-modal-title">
        {isTOTP ? "Verify 2FA code" : "Check your email"}
      </div>
      {!isTOTP ? (
        <div className="auth-copy">
          Enter the security code we sent to your email <b>{email}</b>
        </div>
      ) : (
        <div className="auth-copy">
          Enter the code generated by your authentication app.
        </div>
      )}
      {authMsg.msg && (
        <span
          className="auth-err-msg"
          style={{
            color: !authMsg.isError && "green",
          }}
        >
          {authMsg.msg}
        </span>
      )}
      <OTPField onChangeHandler={handleCodeChange} hasError={authMsg.isError} />
      {!isTOTP && (
        <div onClick={onResendCodeClick} className="code-link">
          Resend Code
        </div>
      )}
      <div className="auth-modal-footer">
        Secured By <b>MetaKeep</b>
      </div>
      {inProgress && <LoaderWithBackdrop />}
    </Modal>
  );
};
