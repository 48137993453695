import { CustomInput } from "../../../../components";
import { Button } from "reactstrap";
import { useForm } from "../../../../hooks";
import { useState } from "react";

export const SignupForm = ({ switchForm, onSignupHandler }) => {
  const { onChangeHandler, values, errors, checkFormValidity } = useForm({
    email: null,
    password: null,
  });
  const [authErr, setAuthErr] = useState(null);

  const { email: emailErr, password: passErr } = errors;

  const onSignUp = async () => {
    try {
      const isValid = checkFormValidity();
      if (!isValid) return;
      const { email, password } = values;
      await onSignupHandler(email, password);
    } catch (err) {
      console.log(err);
      setAuthErr(err.message);
    }
  };

  return (
    <>
      <h1 className="mb-3">Sign up</h1>
      <CustomInput
        placeholder="Email address"
        label="Email address"
        id="email"
        error={emailErr}
        onChangeHandler={onChangeHandler}
      />
      <CustomInput
        placeholder="Password"
        label="Password"
        id="password"
        type="password"
        error={passErr}
        onChangeHandler={onChangeHandler}
      />
      <div className="auth-btn">
        {authErr && <span className="auth-err">{authErr}</span>}
        <Button onClick={onSignUp} className="primary-btn lg round">
          Sign Up
        </Button>
      </div>
      <div className="auth-lnk">
        Already have an account?{" "}
        <span onClick={() => switchForm("SIGN_IN")}>Sign In Here</span>
      </div>
    </>
  );
};
