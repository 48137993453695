import clx from "classnames";
import { Modal, Button, UncontrolledTooltip } from "reactstrap";
import { useState } from "react";
import { BiCopy, BiInfoCircle } from "react-icons/bi";

export const AppViewModal = ({ isOpen, onClose, selectedApp }) => {
  const { name, chain_id, api_key, privateKey = null } = selectedApp;

  const [copied, setCopied] = useState(false);
  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <Modal centered className="app-modal" isOpen={isOpen}>
      <div className="app-modal-title">{name}</div>
      {privateKey && (
        <>
          <div className="modal-note">
            <BiInfoCircle className="warning" /> Please save your <b>Secret</b>.
            We won't be able to recover this.
          </div>
          <div className="app-modal-desc">
            <div className="desc">
              <span className="label">Api Secret</span>{" "}
              <div className="modal-secret">{privateKey}</div>
              <BiCopy
                onClick={() => {
                  onCopy(privateKey);
                }}
                id="appSec"
              />
              <UncontrolledTooltip className="modal-tooltip" target="appSec">
                {copied ? "Copied" : "Copy"}
              </UncontrolledTooltip>
            </div>
          </div>
        </>
      )}
      <div className="app-modal-desc">
        <div className="desc">
          <span className="label">Api Key</span>{" "}
          <div className="modal-secret">{api_key} </div>
          <BiCopy
            onClick={() => {
              onCopy(api_key);
            }}
            id="appId"
          />
          <UncontrolledTooltip className="modal-tooltip" target="appId">
            {copied ? "Copied" : "Copy"}
          </UncontrolledTooltip>
        </div>
      </div>
      <div className="app-modal-desc">
        <div className="desc">
          <span className="label">Chain</span>
          <div className="modal-secret"> {chain_id}</div>
        </div>
      </div>
      <div className="app-modal-cta">
        <Button onClick={onClose} className={clx("primary-btn", "clr-transp")}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
