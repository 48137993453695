//algo for key generation
export const keyAlgorithm = {
  name: "ECDSA",
  namedCurve: "P-256",
};
//algo for msg signing
const signAlgorithm = {
  name: "ECDSA",
  hash: { name: "SHA-256" },
};

/**
 *
 * @param {CryptoKey} key to export
 * @param {string} format can be one of "jwk, "raw", "spki", "pkcs8"
 * @returns {Promise<ArrayBuffer | JsonWebKey>} returns key in either ArrayBuffer or JsonWebKey format
 * @description https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/exportKey
 */
export const exportKey = async (key, format) =>
  await window.crypto.subtle.exportKey(format, key);

/**
 *
 * @param {String} bs64String Base64 string
 * @returns ArrayBuffer
 */
export const base64ToBytesArray = (bs64String) => {
  const decodedString = window.atob(bs64String);
  const buf = new ArrayBuffer(decodedString.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = decodedString.length; i < strLen; i++) {
    bufView[i] = decodedString.charCodeAt(i);
  }
  return buf;
};

export const bytesArrayToBase64 = (bytes) =>
  window.btoa(String.fromCharCode(...new Uint8Array(bytes)));

/**
 * @param {ArrayBuffer} rawPublicKey ArrayBuffer
 * @refrence https://gist.github.com/shanewholloway/6ed5a52fa985b1d23024daa001b6a51e
 * @refrence https://stackoverflow.com/questions/17171542/algorithm-for-elliptic-curve-point-compression/30431547#30431547
 * @returns ArrayBuffer of public key
 *
 */
export const compressPublicKey = (rawPublicKey) => {
  const u8full = new Uint8Array(rawPublicKey);
  const len = u8full.byteLength;
  const u8 = u8full.slice(0, (1 + len) >>> 1); // drop `y`
  u8[0] = 0x2 | (u8full[len - 1] & 0x01); // encode sign of `y` in first bit
  return u8.buffer;
};
/**
 *
 * @returns {Promise<{publicKey: String, privateKey: String}>}
 */
export const generateKeyPair = async () => {
  const { privateKey, publicKey } = await window.crypto.subtle.generateKey(
    keyAlgorithm,
    true,
    ["sign", "verify"]
  );
  const privateKeySecret = (await exportKey(privateKey, "jwk")).d;
  const compressedB64PublicKey = bytesArrayToBase64(
    compressPublicKey(await exportKey(publicKey, "raw"))
  );
  return { privateKey: privateKeySecret, publicKey: compressedB64PublicKey };
};

/**
 *
 * @param {String} d - jwk key
 * @param {String} x - x coordinate
 * @param {String} y - y coordinate
 * @returns CryptoKey Private crypto key
 * @description https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey
 */
export const importPrivateKey = async (d, x, y) => {
  const pvtJWK = {
    crv: keyAlgorithm.namedCurve,
    d,
    ext: true,
    key_ops: ["sign"],
    kty: "EC",
    x,
    y,
  };
  return await window.crypto.subtle.importKey(
    "jwk",
    pvtJWK,
    keyAlgorithm,
    true,
    ["sign"]
  );
};

/**
 *
 * @param {String} bs64Key Public Key in Base64 format
 * @returns x: String, y: String EC coordinates
 */
export const getCurvePointsFromPublicKey = async (bs64Key) => {
  const arrayBuffer = base64ToBytesArray(bs64Key);
  const pubJWK = await exportKey(
    await window.crypto.subtle.importKey(
      "raw",
      arrayBuffer,
      keyAlgorithm,
      true,
      ["verify"]
    ),
    "jwk"
  );
  return { x: pubJWK.x, y: pubJWK.y };
};

/**
 *
 * @param {String} message - message to sign
 * @param {String} secretKey - private key secret
 * @param {String} publicKey - public key in Base64 format
 * @returns String - signature in Base64 format
 */

export const signMessage = async (message = "Hello", secretKey, pubKeyB64) => {
  const { x, y } = await getCurvePointsFromPublicKey(pubKeyB64);
  const privateKey = await importPrivateKey(secretKey, x, y);
  const msgArrayBuffer = new TextEncoder().encode(message);
  const signedMsg = await window.crypto.subtle.sign(
    signAlgorithm,
    privateKey,
    msgArrayBuffer
  );

  return bytesArrayToBase64(signedMsg);
};
