import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { API, Auth } from "aws-amplify";
import "./styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { APP_REGION, POOL_ID, CLINET_ID, APP_ENDPOINT } from "./constants";

Auth.configure({
  region: APP_REGION,
  identityPoolRegion: APP_REGION,
  userPoolId: POOL_ID,
  userPoolWebClientId: CLINET_ID,
});

API.configure({
  endpoints: [
    {
      name: "AppAPI",
      endpoint: APP_ENDPOINT,
      custom_header: async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        };
      },
    },
  ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
