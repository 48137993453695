import { useState, useMemo, useEffect, useCallback } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { GoSignOut } from "react-icons/go";
import { AppModal } from "./AppModal";
import { AppViewModal } from "./AppViewModal";
import { EmptyState, Loader } from "../../components";
import { withAuth } from "../../hoc";
import { generateKeyPair, signMessage } from "../../utils";
import { CHAIN_ID_MAP } from "../../constants";

const NoAppFound = ({ onClick }) => {
  return (
    <div className="empty-state-sub">
      Let's get you started! <span onClick={onClick}>Click here</span> to begin.
    </div>
  );
};

export const Dashboard = withAuth(() => {
  const [modalOpen, toggleModal] = useState(false);
  const [appList, setAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserApps = useCallback(async () => {
    try {
      const resp = await API.get("AppAPI", "/list");
      const apps = resp?.apps || [];
      setAppList(apps);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserApps();
  }, [fetchUserApps]);

  const onSaveApp = async (appName, chain, disableBtns) => {
    try {
      disableBtns(true);
      const { publicKey, privateKey } = await generateKeyPair();
      const signedMsg = await signMessage("Hello", privateKey, publicKey);
      await API.post("AppAPI", "/create", {
        body: {
          chain_id: chain,
          name: appName,
          api_key: publicKey,
          signed_hello_message: signedMsg,
        },
      });
      toggleModal(false);
      fetchUserApps();
      setSelectedApp({
        name: appName,
        chain_id: CHAIN_ID_MAP[chain],
        api_key: publicKey,
        privateKey,
      });
    } catch (err) {
      console.log(err);
      disableBtns(false);
    }
  };

  const onSignOut = async () => {
    await Auth.signOut();
    navigate("/");
  };

  const onSelectApp = ({ name, chainId, apiKeys }) => {
    setSelectedApp({
      name,
      chain_id: CHAIN_ID_MAP[chainId],
      api_key: apiKeys[0]?.apiKey || "",
    });
  };

  const children = useMemo(() => {
    if (isLoading) return <Loader />;
    return appList.length > 0 ? (
      appList.map((elm, idx) => {
        const { name, chainId: chain_id } = elm;
        return (
          <AppCard
            onClick={() => onSelectApp(elm)}
            key={idx}
            appName={name}
            chain={chain_id}
          />
        );
      })
    ) : (
      <EmptyState
        title="No Application Found"
        children={<NoAppFound onClick={() => toggleModal(true)} />}
      />
    );
  }, [appList, isLoading]);
  return (
    <div className="dash-wrapper">
      <div className="main-header">
        <div>
          MetaKeep <br /> <span>Developer Console</span>
        </div>
        <GoSignOut onClick={onSignOut} id="signout" />
      </div>
      <div className="dash-content">
        <div className="sidebar-wrapper">
          <div className="sidebar-content">
            <div className="sidebar-item">
              <span onClick={() => toggleModal(true)}>
                Create New Application +
              </span>
            </div>
          </div>
        </div>
        <div className="main-wrapper">
          <div
            style={{ height: appList.length ? "unset" : "100%" }}
            className="main-content"
          >
            {children}
          </div>
        </div>
      </div>
      {modalOpen && (
        <AppModal
          onClose={() => toggleModal(false)}
          isOpen={modalOpen}
          onSaveApp={onSaveApp}
        />
      )}
      {selectedApp !== null && (
        <AppViewModal
          isOpen={selectedApp !== null}
          selectedApp={selectedApp}
          onClose={() => {
            setSelectedApp(null);
          }}
        />
      )}
    </div>
  );
});

const AppIcon = {
  "Polygon Mainnet": "/images/icons/polygon.svg",
  "Polygon Mumbai": "/images/icons/polygon.svg",
};

const AppCard = ({ appName, chain, onClick }) => {
  const chainName = CHAIN_ID_MAP[chain];
  return (
    <div onClick={onClick} className="app-card">
      <div className="app-card-img">
        <img src={AppIcon[chainName]} alt="" />
      </div>
      <div className="app-desc">
        <div className="app-name">Name: {appName}</div>
        <div className="app-chain">
          Chain: <span>{chainName}</span>
        </div>
      </div>
    </div>
  );
};
