import { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";

import { OTPField, LoaderWithBackdrop } from "../../../../components";
import { useForm } from "../../../../hooks";
export const QRCodeModal = ({ isOpen, codeSrc, onSubmit }) => {
  const { setDirectValue, values, checkFormValidity } = useForm({
    OTP: null,
  });
  const [authErr, setAuthErr] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const { OTP } = values;

  const onVerify = useCallback(async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    try {
      setProgress(true);
      await onSubmit(OTP);
    } catch (err) {
      setAuthErr(err.message);
      console.log("err", err.message);
      setProgress(false);
    }
  }, [OTP, checkFormValidity, onSubmit]);

  useEffect(() => {
    if (OTP && OTP.length === 6) onVerify();
  }, [OTP, onVerify]);

  const handleOtpChange = useCallback(
    (val) => {
      setDirectValue("OTP", val);
    },
    [setDirectValue]
  );

  return (
    <Modal centered className="auth-modal" isOpen={isOpen}>
      <div className="auth-modal-title">Setup 2FA</div>
      <div className="qr-code-container">
        <div className="qr-code-copy">
          Scan the code using 2FA app on your phone and enter the code below
        </div>
        {codeSrc && <QRCodeSVG size={200} value={codeSrc} />}
      </div>
      {authErr && <span className="auth-err-msg">{authErr}</span>}
      <OTPField onChangeHandler={handleOtpChange} hasError={authErr} />
      <div className="auth-modal-footer">
        Secured By <b>MetaKeep</b>
      </div>
      {inProgress && <LoaderWithBackdrop />}
    </Modal>
  );
};
