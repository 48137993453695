import clx from "classnames";
import { useState } from "react";
import { Modal, Input, Button } from "reactstrap";
import { CustomInput } from "../../../components";
import { useForm } from "../../../hooks";

export const AppModal = ({ isOpen, onClose, onSaveApp }) => {
  const { onChangeHandler, values, errors, checkFormValidity } = useForm({
    appName: null,
    chain: null,
  });

  const { appName: appNameErr, chain: chainErr } = errors;
  const { appName, chain } = values;
  const [inProgress, setProgress] = useState(false);
  const onSave = () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    onSaveApp(appName, chain, setProgress);
  };
  return (
    <Modal centered className="app-modal" isOpen={isOpen}>
      <div className="app-modal-title">Create New Application</div>
      <CustomInput
        id="appName"
        label="Application Name"
        placeholder="Enter App Name"
        error={appNameErr}
        onChangeHandler={onChangeHandler}
      />
      <div className="input-wrapper">
        <label htmlFor="chain">Select Chain</label>
        <Input
          id="chain"
          className={clx("text-input", chainErr && "error")}
          label="Select Chain"
          type="select"
          onChange={onChangeHandler}
        >
          <option value={null}></option>
          <option value="CHAIN_ID_POLYGON_MAINNET">Polygon Mainnet</option>
          <option value="CHAIN_ID_POLYGON_MUMBAI">Polygon Mumbai</option>
        </Input>
        {chainErr && <span>{chainErr}</span>}
      </div>
      <div className="app-modal-cta">
        <Button
          disabled={inProgress}
          onClick={onSave}
          className={clx("primary-btn")}
        >
          Save
        </Button>
        <Button
          disabled={inProgress}
          onClick={onClose}
          className={clx("primary-btn", "clr-transp")}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
