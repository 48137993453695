import validator from "password-validator";

export const emailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

const passSchema = new validator();

const otpSchema = new validator();

passSchema
  .has()
  .min(8, "Password should have minimum 8 characters")
  .uppercase(1, "Password must have atleast 1 uppercase letter")
  .symbols(1, "Password must contain atleast one symbol.")
  .digits(1, "Password must contain atleast one number");

otpSchema
  .is()
  .min(6, "OTP should be 6 digits long")
  .max(6, "OTP cannot be more than 6 digits")
  .has()
  .not()
  .letters(0, "Must not contain letters")
  .symbols(0, "Must not contain symbols")
  .spaces(0, "OTP cannot have spaces");

export const validate = (fieldType, value) => {
  const errors = {};
  switch (fieldType) {
    case "email":
      errors[fieldType] = "Please enter a valid Email Address";
      if (value && value.match(emailRegx)) errors[fieldType] = null;
      break;
    case "password":
      errors.password =
        passSchema.validate(value, { details: true })[0]?.message || null;
      break;
    case "appName":
      if (!value) errors.appName = "Application Name is required";
      else errors.appName = null;
      break;
    case "OTP":
      errors.OTP =
        otpSchema.validate(value, { details: true })[0]?.message || null;
      break;
    case "chain":
      if (!value) errors.chain = "Chain is required";
      else errors.chain = null;
      break;
    default:
      errors[fieldType] = null;
      break;
  }
  return errors;
};

export * from "./crypto-utils";
